import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ContactSection } from '../components/contact-section';
import { Hero } from '../components/hero';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { SideBySide } from '../components/side-by-side';
import { ButtonLink } from '../components/ui/button-link';
import ReactModal from 'react-modal';
import { navigate } from 'gatsby';

const LazyOurGallery = React.lazy(() => import('../components/our-gallery'));

function IndexPage(): React.ReactElement {
  const isSSR = typeof window === 'undefined';
  const [showDialog, setShowDialog] = React.useState(true);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  const isBrowser = typeof window !== 'undefined';

  React.useEffect(() => {
    if (isBrowser) {
      let modal = document.getElementById('theModal');
      let closeButton = document.getElementById('close-button');
      let linkButton = document.getElementById('link-button');
      window.addEventListener('click', (e) =>
        e.target === modal ? (modal.style.display = 'none') : null
      );
      closeButton.addEventListener(
        'click',
        (e) => (modal.style.display = 'none')
      );
      linkButton.addEventListener('click', (e) => {
        modal.style.display = 'none';
      });
    }
  }, [isBrowser]);

  return (
    <>
      <SEO title="Home Page" />
      <Layout>
        <div
          id="theModal"
          className="fixed inset-0 z-50 flex w-full h-full overflow-auto bg-transparent-black-50 backdrop-blur"
        >
          <div className="w-full h-full max-w-5xl p-12 mx-auto my-48 prose bg-gray-300 lg:p-24 lg:h-auto">
            <span
              id="close-button"
              className="float-right text-3xl cursor-pointer"
            >
              &times;
            </span>
            <h2 className="text-2xl">Change of Ownership</h2>
            <p>
              Oz Education Early Learning Centres became the new owner and
              approved provider of Cowarra Park Preschool & Long Day Care on
              Friday 11 November 2022. Oz Education is the longest-standing
              Australian family-owned childcare group in Sydney, with a strong
              commitment to quality early education and care for over 40 years.
              Our purpose-built Early Learning Centres located in Sydney and
              across NSW offer safe and stimulating environments where children
              thrive and belong. Beautifully designed, our centres offer
              state-of-the-art facilities, an established preschool program with
              award-winning resources, a comprehensive range of childcare
              services and much more.
            </p>
            <p className="">
              We look forward to supporting young families in and around the
              Lake Cathie area. For more information, please visit the Oz
              Education website by clicking the link below or contact our Lake
              Cathie team at 02-6584 8044 or our Family Service Team at 1300 644
              125.
            </p>
            <a
              id="link-button"
              style={{
                backgroundColor: '#FF8C00',
                textDecorationLine: 'none',
                color: 'white',
              }}
              className="px-12 py-2 mb-24 text-white"
              href="https://www.ozeducation.com.au/centres/lake-cathie-childcare-preschool"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Oz Education Website
            </a>
          </div>
        </div>
        <Hero
          image={
            <StaticImage
              src="../images/home-hero.jpg"
              alt=""
              className="flex-1 object-cover"
            />
          }
          heading={
            <h1 className="text-6xl">
              <span className="font-normal">
                Quality Early <br />
                Childhood Education <br />
              </span>
              <span className="font-light">in Lake Cathie</span>
            </h1>
          }
          cta={
            <ButtonLink variant="teal" to="#contact">
              Enquire Today
            </ButtonLink>
          }
        />
        <OutdoorEnvironment />

        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <LazyOurGallery />
          </React.Suspense>
        )}

        <AboutUs />
        <ContactSection />
      </Layout>
    </>
  );
}

function OutdoorEnvironment(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            Learning in the <br />
            <span className="font-semibold">Outdoor Environment</span>
          </h2>
          <div className="mt-5 prose">
            <p>
              At Cowarra Park Preschool and Long Day Care, we acknowledge that
              choosing that special place to educate and care for your child can
              be a significant decision to make. We hope that this website is
              informative and supports....
            </p>
          </div>
          <p className="mt-12">
            <ButtonLink variant="green" to="/outdoor-environment/">
              Read More
            </ButtonLink>
          </p>
        </SideBySide.ThreeCols>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/home-one.png"
            alt=""
            className="w-[20rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
      </SideBySide>
    </div>
  );
}

function AboutUs(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/home-two.png"
            alt=""
            className="w-[17rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            Find out more <br />
            <span className="font-semibold">About Us</span>
          </h2>
          <div className="mt-5 prose">
            <p>
              Here at Cowarra Park Preschool and Long Day Care, we promise to
              offer children and families in the local area high-quality
              education and care - a place where children feel safe, valued,
              respected and where families feel a sense of belonging in their
              child's care environment.
            </p>
            <p>
              We aim to provide a stimulating environment based on interests
              that enrich abilities and development. Children at Cowarra Park
              Preschool are able to...
            </p>
          </div>
          <p className="mt-12">
            <ButtonLink variant="green" to="/about-us/">
              Read More
            </ButtonLink>
          </p>
        </SideBySide.ThreeCols>
      </SideBySide>
    </div>
  );
}

export default IndexPage;
